<template>
  <div class="flex flex-col gap-y-2 md:gap-y-4">
    <section class="bg-primary">
      <article class="py-4 md:py-12 px-2 md:px-4 max-w-[1200px] mx-auto">
        <div class="flex flex-col md:max-w-[70%] gap-y-6 md:gap-y-8">
          <h1 class="text-white text-xl md:text-4xl">
            Develop a tailored payment system solutions with Payiano
          </h1>

          <p class="text-white text-sm md:text-base">
            Develop a payment solution for any platform – online, mobile, or
            in-person – by setting up a Payiano account designed to meet your
            specific needs. Access all necessary resources for testing and
            launching your project.
          </p>

          <div class="flex">
            <HaButton
              size="xl"
              color="secondary"
              text="Get started!"
              component="router-link"
              :icon="{ icon: PayianoSymbolWhiteIcon, size: 32 }"
              :bind-options="{ to: '/api/rest/get-started' }"
            />
          </div>
        </div>
      </article>
    </section>

    <section class="py-4 px-2 md:px-4 max-w-[1200px] mx-auto">
      <h2 class="text-xl md:text-3xl font-bold mb-2 md:mb-4">
        Discover our APIs and SDKs
      </h2>

      <div class="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4">
        <article
          class="flex flex-col justify-between gap-y-2 px-3 py-2 md:p-4 border rounded-sm md:rounded-md"
        >
          <div class="flex flex-col gap-y-2">
            <h3
              class="flex items-center gap-x-2 text-xl md:text-2xl font-bold text-gray-800"
            >
              <HaIcon :icon="mdiCodeTags" size="30" />

              <span>APIs</span>
            </h3>

            <p class="text-sm md:text-base mb-2">
              Welcome to
              <a :href="domains.payiano" class="de--anchor" target="_blank"
                >Payiano</a
              >'s Developer Hub, your essential resource for integrating
              advanced payment solutions. Our comprehensive APIs and SDKs are
              meticulously designed to support a wide range of payment
              functionalities—from straightforward transactions to sophisticated
              billing and detailed financial analytics.
            </p>
          </div>

          <div class="flex flex-col gap-y-1">
            <router-link
              to="/api/rest/get-started"
              class="de--anchor font-bold text-sm md:text-base"
            >
              Business RESTFUL APIs
            </router-link>
          </div>
        </article>

        <article
          class="flex flex-col justify-between gap-y-2 px-3 py-2 md:p-4 border rounded-sm md:rounded-md"
        >
          <div class="flex flex-col gap-y-2">
            <h3
              class="flex items-center gap-x-2 text-xl md:text-2xl font-bold text-gray-800"
            >
              <HaIcon :icon="mdiInvoiceTextMultipleOutline" size="30" />

              <span>Payment Acceptance</span>
            </h3>

            <p class="text-sm md:text-base mb-2">
              Master the art of payment processing with
              <a :href="domains.payiano" class="de--anchor" target="_blank"
                >Payiano</a
              >'s payment acceptance, ensuring a seamless and secure transaction
              process for your users. Whether you're looking to implement credit
              card payments, reference code, or newer forms of digital payment,
              our detailed documentation covers it all.
            </p>
          </div>

          <div class="flex flex-col gap-y-1">
            <router-link
              to="/api/rest/v1/company/online-payments/payment-links/create"
              class="de--anchor font-bold text-sm md:text-base"
            >
              Payment Links
            </router-link>
          </div>
        </article>

        <article
          class="flex flex-col justify-between gap-y-2 px-3 py-2 md:p-4 border rounded-sm md:rounded-md"
        >
          <div class="flex flex-col gap-y-2">
            <h3
              class="flex items-center gap-x-2 text-xl md:text-2xl font-bold text-gray-800"
            >
              <HaIcon :icon="SocialGithubIcon" size="26" />

              <span>SDKs</span>
            </h3>

            <p class="text-sm md:text-base mb-2">
              Dive into our SDKs to find all the tools you need to seamlessly
              incorporate
              <a :href="domains.payiano" class="de--anchor" target="_blank"
                >Payiano</a
              >'s reliable payment systems into your applications. Enhance your
              offerings with our secure, scalable, and developer-friendly
              payment solutions that promise an exceptional user experience for
              your customers. Start exploring today to see how
              <a :href="domains.payiano" class="de--anchor" target="_blank"
                >Payiano</a
              >
              can transform your payment processes.
            </p>
          </div>

          <div class="flex flex-col gap-y-1">
            <a
              :href="domains.github"
              class="de--anchor font-bold text-sm md:text-base"
              target="_blank"
              rel="noreferrer"
            >
              Browse Github SDKs
            </a>

            <router-link
              to="/plugins/pay-button"
              class="de--anchor font-bold text-sm md:text-base"
            >
              Payiano Pay Button plugin
            </router-link>

            <a
              href="https://github.com/payiano/snippets"
              class="de--anchor font-bold text-sm md:text-base"
              target="_blank"
              rel="noreferrer"
            >
              Verify webhook event snippets
            </a>
          </div>
        </article>

        <article
          class="flex flex-col justify-between gap-y-2 px-3 py-2 md:p-4 border rounded-sm md:rounded-md"
        >
          <div class="flex flex-col gap-y-2">
            <h3
              class="flex items-center gap-x-2 text-xl md:text-2xl font-bold text-gray-800"
            >
              <HaIcon :icon="mdiCodeJson" size="26" />

              <span>Collections</span>
            </h3>

            <p class="text-sm md:text-base mb-2">
              Explore our dedicated collections for
              <a
                :href="domains.postman"
                class="de--anchor"
                target="_blank"
                rel="noreferrer"
                >Postman</a
              >
              and
              <a
                :href="domains.swagger"
                class="de--anchor"
                target="_blank"
                rel="noreferrer"
                >SwaggerUI</a
              >, designed to streamline the integration and testing of
              <a :href="domains.payiano" class="de--anchor" target="_blank"
                >Payiano</a
              >'s APIs. These collections provide pre-configured requests and
              comprehensive examples that help you understand and implement our
              payment solutions swiftly. Both tools are equipped to accelerate
              your development process.
            </p>
          </div>

          <div class="flex flex-col gap-y-1">
            <a
              class="de--anchor font-bold text-sm md:text-base"
              target="_blank"
              :href="config.public.postman.companyUrl"
            >
              Postman Business Collection
            </a>

            <a
              class="de--anchor font-bold text-sm md:text-base"
              target="_blank"
              :href="config.public.postman.partnerUrl"
            >
              Postman Partner Collection
            </a>
          </div>
        </article>
      </div>
    </section>

    <footer class="bg-gray-100">
      <nav
        class="flex flex-col gap-y-6 md:flex-row justify-between py-4 px-2 md:px-4 max-w-[1200px] mx-auto text-sm md:text-bae"
      >
        <ul class="flex gap-x-6">
          <li>
            <a
              :href="domains.payiano"
              class="de--anchor"
              target="_blank"
              aria-label="Payiano Website"
            >
              <HaIcon :icon="PayianoEnglishSideBlueIcon" height="20" />
            </a>
          </li>

          <li>
            <a
              :href="`${domains.payiano}/contact`"
              class="de--anchor font-bold underline"
              target="_blank"
            >
              Support
            </a>
          </li>

          <li>
            <a
              :href="`${domains.payiano}/privacy`"
              class="de--anchor font-bold underline"
              target="_blank"
            >
              Privacy
            </a>
          </li>

          <li>
            <a
              :href="`${domains.payiano}/terms`"
              class="de--anchor font-bold underline"
              target="_blank"
            >
              Terms
            </a>
          </li>
        </ul>

        <ul class="flex justify-center items-center gap-x-3">
          <li>
            <a
              :href="domains.github"
              target="_blank"
              title="Github"
              aria-label="Github Workspace"
              rel="noreferrer"
            >
              <HaIcon :icon="SocialGithubIcon" height="24" />
            </a>
          </li>

          <li>
            <a
              :href="domains.postman"
              target="_blank"
              title="Postman"
              aria-label="Postman Workspace"
              rel="noreferrer"
            >
              <HaIcon :icon="SocialPostmanIcon" height="26" />
            </a>
          </li>
        </ul>
      </nav>
    </footer>
  </div>
</template>

<script setup lang="ts">
import {
  mdiCodeJson,
  mdiCodeTags,
  mdiInvoiceTextMultipleOutline
} from '@mdi/js'
import { HaButton, HaIcon } from '@payiano/ha-atoms'
import {
  PayianoEnglishSideBlueIcon,
  PayianoSymbolWhiteIcon,
  SocialGithubIcon,
  SocialPostmanIcon
} from '@payiano/hc-icons'
import { domains } from '@/constants'

definePageMeta({
  layout: 'home'
})

const config = useRuntimeConfig()

useStructuredData({
  breadcrumbs: [
    {
      name: 'Documentation',
      url: config.public.siteUrl
    }
  ]
})
</script>
